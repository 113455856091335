

import { Vue, Component, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { Machine } from '@/types/material.d'
import { phone, idCardNo, pInteger } from '@/utils/validate'
@Component
export default class extends Vue {
  @Ref('form') readonly form!: ElForm
  formData: any = {
    machineryName: '', // 机械名称
    specificationModel: '', // 规格/型号
    supplierId: '', // 所属供应商
    unitPrice: '',
    whetherOil: '2', // 是否含油
    driver: '', // 驾驶员姓名
    driverLicenseNum: '', // 身份证号
    driverMobile: '', // 手机号
    engine: '', // 发动机编号
    remarks: '' // 备注
  }

  private supplierData = []
  private oilList = []

  rules = {
    machineryName: [
      { required: true, message: '请输入机械名称', trigger: 'blur' }
    ],
    specificationModel: [
      { required: true, message: '请输入规格型号', trigger: 'blur' }
    ],
    unitPrice: [
      { required: true, message: '请输入单价', trigger: 'blur' },
      { validator: pInteger }
    ],
    driverLicenseNum: [
      { validator: idCardNo }
    ],
    driverMobile: [
      { validator: phone }
    ]
  }

  get machineryId () {
    return this.$route.query.machineryId as string
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  created () {
    if (this.machineryId) {
      this.loadData()
    }
    this.loadSupplierType()
    this.getOilList()
  }

  loadData () {
    this.$axios.get<Machine>(this.$apis.material.selectYhMachineryByMachineryId, {
      machineryId: this.machineryId
    }).then(res => {
      this.formData = res || {}
    })
  }

  // 是否含油
  getOilList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'whetherOil' }).then(res => {
      this.oilList = res.whetherOil || []
    })
  }

  loadSupplierType () {
    this.$axios.get(this.$apis.material.selectYhSupplierByPage).then(res => {
      this.supplierData = res.list
    })
  }

  submit () {
    this.form.validate(valid => {
      if (valid) {
        if (this.formData.project === '') {
          this.formData.project = null
        }
        // 编辑去除字段
        delete this.formData.whetherOilName
        const url = this.machineryId ? this.$apis.material.updateYhMachinery : this.$apis.material.insertYhMachinery
        this.$axios.post(url, this.formData).then(() => {
          this.$router.back()
        })
      }
    })
  }
}
